import moment from "moment";

export function getPricingForCompositionExtra({ composition, pricings = [] }) {
  const adults = composition?.adults ?? composition?.adultCount ?? 0;
  const childrenBirthdates = composition?.childrenBirthdates ?? [];
  const formattedChildrenBirthdates = childrenBirthdates.map((birthdate) =>
    moment(birthdate).format('YYYY-MM-DD'),
  );

  return pricings?.find((price) => {
    return (
      price.validForAdultCount === adults &&
      price.validForChildrenBirthdates.length === childrenBirthdates.length &&
      price.validForChildrenBirthdates.every((birthdate) =>
        formattedChildrenBirthdates.includes(birthdate),
      )
    );
  });
}
