import React from 'react';
import { useTable } from 'react-table';
import { useSortBy } from 'react-table';
import styled from 'styled-components';

const Td = styled.td`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
`;

export default function KoobTableSortable({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({columns, data}, useSortBy);

  return (
    <div className="flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200" {...getTableProps()}>
              <thead className="">
              {headerGroups.map(headerGroup => {
                const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();

                return (
                  <tr key={key} {...restHeaderGroupProps}>
                    {headerGroup.headers.map(column => {
                      const { key, ...restColumnProps } = column.getHeaderProps(column.getSortByToggleProps({
                        ...column.getSortByToggleProps(),
                        title: column.title ? column.title : column.getSortByToggleProps().title,
                      }));
                      return (
                        <th
                          key={key}
                          {...restColumnProps}
                          title=""
                          scope="col"
                          className="py-3 text-sm text-left text-gray-900 font-bold tracking-wide"
                        >
                          <div className="inline-flex space-x-2 items-center">
                            <span>{column.render('label')}</span>
                            {column.customContent && (
                              <span>{column.customContent}</span>
                            )}
                            {column.render('label') && !column.cannotBeReordered &&
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? (
                                      <svg className="h-3 w-3" viewBox="0 0 320 512">
                                        <path d="M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z"/>
                                      </svg>
                                    )
                                    : (
                                      <svg className="h-3 w-3" viewBox="0 0 320 512">
                                        <path d="M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"/>
                                      </svg>
                                    )
                                  : (
                                    <svg className="h-3 w-3" viewBox="0 0 320 512">
                                      <path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/>
                                    </svg>
                                  )
                                }
                              </span>
                            }
                          </div>
                        </th>
                      )
                    })}
                  </tr>
                )
              })}
              </thead>
              <tbody className="divide-y divide-gray-200" {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row);

                  const { key, ...restRowProps } = row.getRowProps();

                  return (
                    <tr key={key} {...restRowProps}>
                      {row.cells.map(cell => {
                        const { key, ...restCellProps } = cell.getCellProps();

                        return (
                          <Td key={key} {...restCellProps} className="whitespace-nowrap text-sm text-gray-500 py-6">
                            {cell.render('Cell')}
                          </Td>
                        );
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
