import styled, { css, useTheme } from 'styled-components';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import EditReservationIcon from '../../../images/EditReservationIcon.svg';
import React, { useEffect, useState } from 'react';
import { TextButton } from 'components/Hotel/Booking/components';
import { media } from 'ui';
import { useBooking } from 'hooks';
import { Stack } from '@koob/margaret';
import { formatCurrency } from '../../../utils';
import { Lightning } from '../../Lightning';
import Select from 'react-select';
import RoomTags from '../../Booking/RoomTags';
import { HotelRoomPromotionSupplement } from './HotelBookedSummary';
import { uniqBy } from 'lodash';
import KoobBadge from '../../Koob/KoobBadge';
import Carousel, { Modal, ModalGateway } from 'react-images';

const ChangedButton = styled(TextButton)`
    width: 105px !important;
    height: 27px;
`;

const RoomExtraContent = styled(Stack)`
    padding-left: 0;
    padding-right: ${({theme}) => theme.spacing()};

    ${media.tablet`
    padding-left: ${({theme}) => theme.spacing()};
  `}
    p {
        margin-top: ${({theme}) => theme.spacing(0.5)};
        margin-bottom: ${({theme}) => theme.spacing(0.5)};
    }
`;

const RoomTitle = styled.h1`
    font-size: 18px;
    font-weight: 600;
    margin: ${({theme}) => theme.spacing(0)};
`;

export const Promotion = styled(Stack).attrs({
  alignY: 'center',
  gutterSize: 0.25,
  marginBottom: 0.5,
})`
    font-size: 0.75rem;
    padding: ${({theme}) => theme.spacing(0.25)} ${({theme}) => theme.spacing(0.75)};
    border-radius: 40px;
    color: #2e1065;
    background-color: ${({bgColor}) => bgColor ?? '#e9d5ff'};

    ${props =>
            props.promo &&
            css`
                padding: ${({theme}) => theme.spacing(0.2)};
                margin-top: -1px !important;
            `
    }
`;

export const BedTag = styled(Stack).attrs({
  gutterSize: 0.5,
  alignY: 'center',
})`
    cursor: pointer;
    border: 1px solid ${({theme}) => theme.separator};
    padding: 3px 10px;
    ${props =>
            props.selected &&
            css`
                background: ${({theme}) => theme.orangeLight2};
                color: ${({theme}) => theme.orange};
            `}
    ${props =>
            props.red &&
            css`
                background-color: #ffe3e3;
                color: #ea5d5d !important;
            `}
    ${props =>
            props.grey &&
            css`
                color: rgba(0, 0, 0, 0.5);
                background-color: #eeeeee;
            `}
    ${props =>
            props.green &&
            css`
                background: rgba(115, 201, 146, 0.12);
                color: #73c992;
            `}

    border-radius: 5px;
`;

const HotelRoomPromotion = ({name}) => {
  return (
    <Promotion>
      <div>{name}</div>
    </Promotion>
  );
};

const Separator = styled.div`
    width: 100%;
    border-top: 1px solid ${({theme}) => theme.separator};
    margin-top: ${({theme}) => theme.spacing(1)};
    margin-bottom: ${({theme}) => theme.spacing(1)};
`;

function RoomFeature({ icon, iconUrl, children }) {
  return (
    <div className="flex space-x-1.5 items-center text-gray-600">
      {iconUrl ? (
        <img
          className="h-4 w-4"
          src={iconUrl}
          alt=""
        />
      ) : (
        <i className={['far', icon].join(' ')}/>
      )}

      <div>
        {children}
      </div>
    </div>
  )
}

export function RoomCard({
  room,
  bed,
  allBeds,
  changeSelectedBed,
  roomIndex,
  possibility,
  deleteRoom,
  selected,
  filters: updateFilters,
  selectedRoomsFromPromotionSelect,
  isSelected,
  currentBedIndex,
  promoList,
  nRoomIndex,
  setSelectedPromotionSupplemnts,
  selectedPromotionSupplemnts
}) {
  const {
    filters: creationFilters,
    selectedSupplements,
    setSelectedSupplements,
    currentSelectedRoom,
    setCurrentSelectedRoom,
    selectedRooms
  } = useBooking();
  const filters = updateFilters ?? creationFilters;
  const {t} = useTranslation('booking', {keyPrefix: 'room'});
  const theme = useTheme();
  const HEIGHT = 42;
  const [values, setValues] = useState();
  const [selectedPromotion, setSelectedPromotion] = useState(0);
  const [selectedPromotionSupp, setSelectedPromotionSupp] = useState();
  const [clicked, setClicked] = useState(false);
  const [picturesModalIsOpen, setPicturesModalIsOpen] = useState(false);

  let promotionIndex = 0;
  const promotionOptions =
    possibility.allPromotions
      ?.flatMap(promotionCombinations => {
        return Object.entries(promotionCombinations).map(
          ([key, promotionCombination]) => {
            if (
              bed &&
              bed.id.split('##').pop() === key.split('##').pop() &&
              promotionCombination.length
            ) {
              const result = {
                label: promotionCombination.join(', '),
                value: key,
                index: promotionIndex,
                promotions: promotionCombination,
              };
              promotionIndex++;
              return result;
            } else {
              return null;
            }
          },
        );
      }).filter(item => item !== null) ?? [];

  const promotionOptionsFiltered = (promotionOptions || []).filter(item => !promoList.some(sup => sup.value === item.label));
  const koediaPromos = bed?.promotions?.filter(item => item !== null);
  const filteredOptionSupplement = uniqBy(promoList.filter(({value}) => {
    const isPromo =
      possibility.bedChoices[0].optionalSupplements?.some(item => item.name === value);
    return isPromo;
  }), 'displayName');

  const handleClick = (e) => {
    if (clicked) {
      e.stopPropagation();
    }else{
      setClicked(true);
    }
  };

  const renderPromotions = () => {
    const promotions = selected ? bed?.promotions : promotionOptions[selectedPromotion]?.promotions;
    const supplements = bed?.totalPriceWithoutPromo !== bed?.totalPriceWithPromo ? bed?.mandatorySupplements : [];
    if (bed?.promotions?.length > 0 || supplements?.length > 0) {
      return (
        <RoomExtraContent size="full" direction="column" gutterSize={0.5}>
          <Separator/>

          <Stack
            style={{
              flexWrap: 'wrap',
              gap: '0.5rem',
            }}
          >
            {promotions?.map((name, index) => (
              <HotelRoomPromotion key={index} name={name}/>
            ))}

            {supplements?.map((name, index) => (
              <HotelRoomPromotionSupplement
                label={t('booking:summary:requiredSupplement')}
                key={index}
                supplement={name}
              />
            ))}
          </Stack>
        </RoomExtraContent>
      );
    }
  }

  useEffect(() => {
    if (isSelected && selectedPromotion !== 0) {
      setSelectedPromotion(values?.index ?? selectedPromotion);
      setClicked(false);
    }
    if (selected && selectedPromotionSupp?.length > 0) {
      setSelectedSupplements([]);
      setSelectedPromotionSupp(null)
    }
    if (isSelected && selectedRoomsFromPromotionSelect) {
      selectedRoomsFromPromotionSelect(possibility.originalIndex, values?.label, bed?.name)
    }
  }, [currentBedIndex, isSelected]);

  useEffect(() => {
    setSelectedPromotion(0);
    setValues(undefined);
    if (selectedPromotionSupp?.length > 0) {
      setSelectedSupplements([]);
    }
  }, [isSelected])

  useEffect(() => {
    if (!isSelected && selectedRoomsFromPromotionSelect) {
      setClicked(false);
      setSelectedPromotion(values?.index ?? 0)
      selectedRoomsFromPromotionSelect(possibility.originalIndex, values?.label, bed?.name)
    }
  }, [selectedPromotion, values]);

  useEffect(() => {

    const promosPrice = selectedSupplements?.reduce((total, {price}) => total + price, 0)

    setCurrentSelectedRoom({
      ...currentSelectedRoom,
      bed: {
        ...currentSelectedRoom?.bed,
        totalPriceWithPromo: currentSelectedRoom?.bedChoices?.[0]?.totalPriceWithPromo + promosPrice,
      },
      optionalPromos: selectedSupplements
    });
    setSelectedPromotionSupp(selectedSupplements);

  }, [selectedSupplements]);

  const totalTravelers = Math.max(
    filters?.rooms
      ?.reduce((acc, room) => acc + room.adults + room.children, 0)
    , 1
  );

  const selectedPromo = promotionOptionsFiltered?.find(item => item.label === selectedRooms?.[selectedRooms?.length - 1]?.bed?.promotions?.[0]);

  return (
    <div className="w-full">
      <div className="flex justify-between gap-5">
        <div className="flex flex-col place-content-center">
          <div className="flex flex-wrap items-center gap-3">
            <div className="text-xl font-semibold">
              {get(possibility, 'name', '')} {bed?.name ? `(${bed?.name})` : ''}
            </div>

            <div className="flex flex-wrap items-center gap-2">
              <RoomTags room={bed}/>
            </div>

            {possibility?.allotmentAvailable && (
              <div className="-my-2">
                <Lightning popover={t('instantAvaibility')}/>
              </div>
            )}
          </div>

          {possibility?.description && (
            <div
              className="mt-1 text-gray-500"
              dangerouslySetInnerHTML={{__html: possibility.description}}
            />
          )}

          <div className="mt-5 flex flex-wrap gap-3">
            {possibility?.supplierCode && (
              <RoomFeature icon="fa-link">
                <KoobBadge>
                  {`${possibility?.supplierCode}`}
                </KoobBadge>
              </RoomFeature>
            )}

            {possibility?.roomCapacity && (
              <RoomFeature icon="fa-bed-front">
                {t('capacity', { count: possibility.roomCapacity })}
              </RoomFeature>
            )}

            {/*<RoomFeature icon="fa-dollar">*/}
            {/*  {t('pricePerDay', {*/}
            {/*    amount: formatCurrency({*/}
            {/*      amount: get(bed, 'totalPriceWithPromo', 0) / nbNights,*/}
            {/*      currency: get(bed, 'currency'),*/}
            {/*    }),*/}
            {/*  })}*/}
            {/*</RoomFeature>*/}

            {possibility?.facilities?.map((facility, index) => (
              <RoomFeature
                key={index}
                icon="fa-check"
                iconUrl={facility.iconUrl}
              >
                {facility.name}
              </RoomFeature>
            ))}

            {possibility?.attachments?.length > 0 && (
              <div
                onClick={() => setPicturesModalIsOpen(true)}
                className="cursor-pointer"
              >
                <KoobBadge color="orange">
                  <i className="far fa-images mr-1.5"/>
                  {t('showAllPictures')}
                </KoobBadge>
              </div>
            )}
          </div>
        </div>

        <div className="flex-shrink-0 w-40">
          <div className="text-right">
            <div className="text-lg text-orange-600 font-semibold">
              {formatCurrency({
                amount: get(bed, 'totalPriceWithPromo', 0),
                currency: get(bed, 'currency'),
              })}
            </div>

            <div className="text-sm text-gray-600 font-medium">
              {formatCurrency({
                amount: get(bed, 'totalPriceWithPromo', 0) / totalTravelers,
                currency: get(bed, 'currency'),
              })} / PAX
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5 flex justify-between items-end">
        <div>
          {(Array.isArray(possibility.allPromotions) === true &&
            possibility.allPromotions.length > 1 &&
            promotionOptionsFiltered.length > 1) && (
            <div>
              <div className="mb-1.5 text-lg font-medium">
                <i className="fal fa-tag mr-1.5"/>
                {t('choosePromotion')}
              </div>

              <div onClick={handleClick}>
                <Select
                  onMenuOpen={() => {
                    setClicked(true);
                    if(!isSelected && selectedRoomsFromPromotionSelect){
                      setSelectedPromotion(values?.index);
                      selectedRoomsFromPromotionSelect(possibility.originalIndex, values?.label, bed.name)
                    }
                    setClicked(false);
                  }}
                  placeholder={'Select a promotions'}
                  name="promotions"
                  options={promotionOptionsFiltered}
                  defaultValue={promotionOptionsFiltered[0]}
                  value={selected? selectedPromo : promotionOptionsFiltered[isSelected ? selectedPromotion : 0]}
                  onChange={value => {
                    setValues(value);
                    setSelectedPromotion(value.index);
                    if (selectedRoomsFromPromotionSelect) {
                      selectedRoomsFromPromotionSelect(possibility.originalIndex, value.label, bed.name)
                    }
                  }}
                  isDisabled={selected}
                  isSearchable={false}
                  isClearable={false}
                  styles={{
                    control: baseStyles => ({
                      ...baseStyles,
                      borderRadius: '5px',
                      height: HEIGHT,
                      width: filteredOptionSupplement?.length > 0 ? 'fit-content' : '350px',
                      minWidth: '350px',
                      padding: '0 0.5rem',
                      border: `1px solid ${theme.separator}`,
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isFocused ? 'transparent' : 'transparent',
                      color: state.isSelected ? 'default color' : 'default color',
                      ':hover': {
                        backgroundColor: 'color when option is hovered'
                      }
                    }),
                  }}
                />
              </div>
            </div>
          )}

          {(Array.isArray(filteredOptionSupplement) === true && filteredOptionSupplement?.length > 0) && (
            <div>
              <div className="mb-1.5 text-lg font-medium">
                <i className="fal fa-tag mr-1.5"/>
                {t('chooseOptionalPromotion')}
              </div>

              <div
                onClick={handleClick}
              >
                <Select
                  placeholder={'Select supplements'}
                  name="promotions"
                  {...(selected ? { defaultValue: selectedSupplements } : {})}
                  value={selected ? selectedPromotionSupplemnts?.[nRoomIndex]  : selectedPromotionSupp}
                  key={nRoomIndex}
                  options={filteredOptionSupplement}
                  getOptionValue={value => value?.id}
                  getOptionLabel={value => value?.displayName}
                  onMenuOpen={() => {
                    setClicked(true);
                    if(!isSelected && selectedRoomsFromPromotionSelect){
                      setSelectedPromotion(values?.index);
                      selectedRoomsFromPromotionSelect(possibility.originalIndex, values?.label, bed.name)
                    }
                    setClicked(false);
                  }}
                  onChange={value => {
                    setSelectedSupplements(value);
                    setSelectedPromotionSupplemnts(prev => {
                      return {
                        ...prev,
                        [nRoomIndex]: value
                      }
                    });
                  }}
                  isDisabled={selected}
                  isSearchable={false}
                  isClearable={false}
                  isMulti
                  className="basic-multi-select"
                  styles={{
                    control: baseStyles => ({
                      ...baseStyles,
                      borderRadius: '5px',
                      padding: '0 0.5rem',
                      border: `1px solid ${theme.separator}`,
                      width: (selectedSupplements?.length > 0 || selected) ? 'fit-content' : '350px',
                      minWidth: '350px',
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isFocused ? 'transparent' : 'transparent',
                      color: state.isSelected ? 'default color' : 'default color',
                      ':hover': {
                        backgroundColor: 'color when option is hovered'
                      }
                    }),
                  }}
                />
              </div>
            </div>
          )}

          {renderPromotions()}

          {possibility?.supplier === 'Koedia' && koediaPromos?.length > 0 && (
            <div className="flex flex-wrap gap-3">
              {koediaPromos?.map((name, index) => (
                <HotelRoomPromotion key={index} name={name}/>
              ))}
            </div>
          )}

          {(allBeds ?? []).length > 1 && (
            <div>
              <div className="mb-1.5 text-lg font-medium">
                <i className="fal fa-bed mr-1.5"/>
                {t('selectedComposition')}
              </div>

              <div className="flex flex-wrap gap-3">
                {(allBeds ?? []).map((bedElement, index) => (
                  <BedTag
                    key={index}
                    onClick={e => {
                      e.stopPropagation();
                      if (currentBedIndex !== index) {
                        changeSelectedBed(index);
                      }
                      
                    }}
                    selected={bed?.id === bedElement?.id}
                  >
                    {bedElement.name}
                  </BedTag>
                ))}
              </div>
            </div>
          )}

          {selected && (
            <Stack size="full" alignX="end">
              <ChangedButton
                type="button"
                onClick={() => {
                  deleteRoom(roomIndex);
                }}
              >
                {t('update')}
                <img
                  src={EditReservationIcon}
                  alt=""
                  style={{marginLeft: '8px', height: '15px', width: '15px'}}
                />
              </ChangedButton>
            </Stack>
          )}
        </div>

        <div className="-mt-5 flex justify-end space-x-3 items-center">
          <div className="text-right text-gray-600 font-medium">
            {get(possibility, 'source', undefined)}
          </div>

          {get(possibility, 'sourceLogo', undefined) && (
            <img
              className="h-8 w-8 bg-gray-200 rounded-lg"
              src={get(possibility, 'sourceLogo', undefined)}
              alt=""
            />
          )}
        </div>
      </div>

      <ModalGateway>
        {picturesModalIsOpen && (
          <Modal onClose={() => setPicturesModalIsOpen(false)}>
            <Carousel views={possibility?.attachments?.map(img => {
              return {
                src: img?.url,
                caption: img?.name
              }
            })} />
          </Modal>
        )}
      </ModalGateway>
    </div>
  );
}
