import { post } from './';

export const login = payload =>
  post(`/api/v1/oauth/token`, {
    ...payload,
  });

export const refreshToken = () =>
  post(`/api/v1/oauth/token`, {
    refreshToken: localStorage.getItem('refreshToken'),
  });

export const signUp = payload =>
  post(`/api/v1/auth/signup`, {
    ...payload,
  });
