import { Routes, Route } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from './contexts';
import AppProvider from 'providers/AppProvider';
import RoutesProvider from 'providers/RoutesProvider';
import { lazy } from 'react';
import { Navigate } from 'react-router';
import Folders from './containers/Folders';
import BookingProvider from "./providers/BookingProvider";
import ExperienceBookingProvider from "./providers/ExperienceBookingProvider";
import LanguageProvider from 'providers/LanguageProvider';
import TripDesignerProvider from './providers/TripDesignerProvider';
import TripDesignerRoutes from './containers/TripDesigner/routes';
import NotFound from './containers/NotFound';
import TripsRoutes from './containers/Trips';
import UserImpersonationAlert from './components/UserImpersonationAlert';

const Dashboard = lazy(() => import('./containers/Dashboard'));
const Organizations = lazy(() => import('containers/Organizations'));
const Users = lazy(() => import('containers/Users'));
const Hotels = lazy(() => import('containers/Hotels'));
const Bookings = lazy(() => import('containers/Bookings'));
const Dmcs = lazy(() => import('containers/Dmcs'));
const Experiences = lazy(() => import('containers/Experiences'));

const App = () => {
  const { accessToken } = useContext(AuthContext);

  if (!Boolean(accessToken)) {
    return <Navigate to="/login" />;
  }

  return (
    <RoutesProvider>
      <AppProvider>
        <BookingProvider>
          <ExperienceBookingProvider>
            <TripDesignerProvider>
              <LanguageProvider>
                <UserImpersonationAlert />

                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="hotels/*" element={<Hotels />} />
                  <Route path="experiences/*" element={<Experiences />} />
                  <Route path="bookings/*" element={<Bookings />} />
                  <Route path="users/*" element={<Users />} />
                  <Route path="organizations/*" element={<Organizations />} />
                  <Route path="dmcs/*" element={<Dmcs />} />
                  <Route path="folders/*" element={<Folders />} />
                  <Route path="trip-designer/*" element={<TripDesignerRoutes />} />
                  <Route path="trips/*" element={<TripsRoutes />} />
                  <Route
                    path="*"
                    element={<NotFound />}
                  />
                </Routes>
              </LanguageProvider>
            </TripDesignerProvider>
          </ExperienceBookingProvider>
        </BookingProvider>
      </AppProvider>
    </RoutesProvider>
  );
};

export default App;
