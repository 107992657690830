export const API_NODE_BASE =
  process.env.REACT_APP_API_NODE_BASE_URL
  ?? process.env.REACT_APP_API_BASE_URL;

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const HTML_TO_PDF_API_URL =
  process.env.REACT_APP_HTML_TO_PDF_API_URL
  ?? 'https://html-to-pdf.koob.tech/html-to-pdf';
export const HTML_TO_PDF_API_KEY = process.env.REACT_APP_HTML_TO_PDF_API_KEY;

export const KOEDIA = 'KOEDIA';
export const RAKUTEN = 'RAKUTEN';

export const DEFAULT_LOCAL = 'en';
