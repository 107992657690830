import DesignTripModal from "components/Trip/DesignTripModal";
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getTrip } from '../../api/trip';
import { useTripDesigner } from '../../hooks';
import Spinner from '../../components/Spinner';
import { getAllCountries } from '../../api/countries';
import { formatTripFromApi } from './utils';
import KoobLayout from '../../components/Koob/KoobLayout';

export const DesignTrip = () => {
  const { fromId } = useParams();
  const [searchParams] = useSearchParams();
  const fromDraft = searchParams.get('from') === 'draftEdit';
  const { setTripData, setInitialExtras, tripFilters, setTripFilters, resetContext } = useTripDesigner();

  const [showDesignTripModal, setShowDesignTripModal] = useState(true);
  const [loading, setLoading] = useState(!!fromId);

  useEffect(() => {
    if (fromId) {
      resetContext();

      getTrip({ tripId: fromId })
        .then(async (res) => {
          const trip = res.data;

          const { days, initialExtras } = await formatTripFromApi(trip);

          const countries = [];

          getAllCountries()
            .then(res => {
              return res.data
                .filter(country => trip.countries.find(c => c.id === country.id))
                .map(country => {
                  countries.push({
                    label: country.title,
                    value: country.id
                  });
                });
            });

          await Promise.all([countries]);

          setInitialExtras(initialExtras);

          setTripData({
            days: days,
            clientFolder: trip.clientFolder
          });

          setTripFilters({
            ...tripFilters,
            programName: trip.name,
            tripDuration: trip.duration,
            dmcId: {value: trip.dmc?.id, label: trip.dmc?.displayName},
            minPax: trip.minPax,
            maxPax: trip.maxPax,
            countries: countries,
            startLocation: trip.startLocation,
            endLocation: trip.endLocation,
            departureDate: new Date(trip?.startDate),
            templateId: trip?.template?.id,
            adultPax: trip?.adultPax,
            childrenBirthdates: trip?.childrenBirthdates?.map((dateString) => new Date(dateString).toISOString()),
            clientFolder:
              (trip?.template && !fromDraft)
                ? null
                : trip.clientFolder,
            tripId: trip.id,
          });
        })
        .finally(() => {
          setLoading(false);
        })
    }
  }, [fromId]);

  return (
    <KoobLayout>
      {!loading ? (
        <DesignTripModal
          open={showDesignTripModal}
          close={() => {setShowDesignTripModal(false)}}
        />
      ) : (
        <div className="mt-20">
          <Spinner noAbsolute />
        </div>
      )}
    </KoobLayout>
  );
}
