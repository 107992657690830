import { Popover } from '@headlessui/react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../../components';
import KoobBadge from '../../../../components/Koob/KoobBadge';
import { useTripDesigner } from '../../../../hooks';
import { useField } from 'formik';
import { getTrip, saveTrip } from '../../../../api/trip';
import { formatTripFromApi } from '../../utils';
import { useState } from 'react';
import KoobPlaceholder from '../../../../components/Koob/KoobPlaceholder';

function CompareButton({ version, onClick, active, disabled }) {
  const { t } = useTranslation('tripDesigner');
  const {setCompareVersionTotalPrice} = useTripDesigner();

  return (
    <button
      onClick={() => {
        setCompareVersionTotalPrice(null)
        onClick(!active ? version : null);
      }}
      className={[
        'group border-none transition z-20',
        (disabled && !active) ? 'bg-gray-100 opacity-50 cursor-not-allowed' : 'bg-blue-100 hover:bg-blue-500',
        active && 'bg-blue-500 text-white',
      ].join(' ')}
      disabled={disabled && !active}
    >
      <Tooltip
        tip={t('compose.versions.compareButtonTooltip')}
        position="left"
        disabled={disabled || active}
      >
        <div className="h-10 w-10 flex justify-center items-center">
          <i className={[
            'far fa-eye text-lg text-gray-600 transition',
            (!disabled && !active) && 'group-hover:text-white',
          ].join(' ')}
          />
        </div>
      </Tooltip>
    </button>
  )
}

function ExportButton({ tripId, versionId }) {
  const { t } = useTranslation('tripDesigner');

  const openExport = () => {
    window.open(`/trips/${tripId}/export?version=${versionId}`, '_blank');
  }

  return (
    <button
      onClick={() => openExport()}
      className="group bg-orange-100 hover:bg-orange-500 border-none transition z-20"
    >
      <Tooltip
        tip={t('compose.versions.exportButtonTooltip')}
        position="left"
      >
        <div className="h-10 w-10 flex justify-center items-center">
          <i className="far fa-book text-lg text-gray-600 group-hover:text-white transition" />
        </div>
      </Tooltip>
    </button>
  )
}

export default function ComposeVersionSelector({ tripId, versions, currentVersion, reloadTrip }) {
  const { t } = useTranslation('tripDesigner');
  const { compareWithVersion, setCompareWithVersion, setInitialExtras } = useTripDesigner();
  const [ , , { setValue: setDays }] = useField('days');
  const [loading, setLoading] = useState(false);

  const switchToVersion = async (version) => {
    setLoading(true);

    if (compareWithVersion) {
      setCompareWithVersion(null);
    }

    const tripData = await getTrip({
      tripId,
      versionId: version.id
    });
    const trip = tripData.data;

    await saveTrip({
      ...trip,
      currentVersion: {
        id: version.id
      }
    });
    reloadTrip();

    const { days, initialExtras } = await formatTripFromApi(trip);

    setDays(days);
    setInitialExtras(initialExtras);
    setLoading(false);
  }

  return (
    <Popover className="relative">
      <Popover.Button className="h-full border-none focus:outline-none">
        <div className="h-full border text-gray-600 font-medium px-4 rounded-full">
          <div className="h-full flex justify-center items-center space-x-1.5">
            <i className="far fa-code-merge" />

            <div className="flex items-center">
              {t('compose.versions.version')} {currentVersion?.version ?? <KoobPlaceholder className="ml-1 h-5 w-4 rounded" />}
            </div>
          </div>
        </div>
      </Popover.Button>

      <Popover.Panel className="fixed mt-3 z-10">
        <div
          className={[
            'relative bg-white h-72 w-[500px] rounded-lg shadow-k p-3',
            !loading ? 'overflow-y-scroll' : 'overflow-y-clip'
          ].join(' ')}
        >
          {loading && (
            <div className="absolute z-30 top-0 left-0 h-full w-full bg-black/25 flex justify-center items-center">
              <i className="far fa-refresh text-orange-500 text-3xl animate-spin" />
            </div>
          )}

          {versions?.map((version) => (
            <div
              key={version.id}
              className="relative flex border-b"
            >
              <div
                onClick={() => switchToVersion(version)}
                className={[
                  'w-full hover:bg-gray-50 py-3 px-4 border-l-4 transition cursor-pointer',
                  currentVersion.id === version.id && 'border-orange-500',
                  compareWithVersion?.id === version.id && 'border-blue-500',
                ].join(' ')}
              >
                <div className="w-full flex justify-between items-center">
                  <div className="w-56">
                    <div className="text-sm font-medium text-gray-700">
                      {t('compose.versions.version')} {version.version}
                    </div>

                    <div className="text-xs font-medium text-gray-500">
                      {t('compose.versions.by')} {version.user.name}

                      <div className="mt-1 flex">
                        {['dmc_admin', 'dmc_user'].includes(version.user.role?.name) && (
                          <KoobBadge
                            size="sm"
                            color="purple"
                            className="ml-1"
                          >
                            DMC
                          </KoobBadge>
                        )}
                        {['to_admin', 'to_user'].includes(version.user.role?.name) && (
                          <KoobBadge
                            size="sm"
                            color="orange"
                            className="ml-1"
                          >
                            TO
                          </KoobBadge>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="text-xs text-gray-400">
                    {moment(version.createdAt).fromNow()}
                  </div>
                </div>
              </div>

              <CompareButton
                version={version}
                onClick={setCompareWithVersion}
                active={compareWithVersion?.id === version.id}
                disabled={version.current || compareWithVersion}
              />

              <ExportButton
                tripId={tripId}
                versionId={version.id}
              />
            </div>
          ))}
        </div>
      </Popover.Panel>
    </Popover>
  )
}
