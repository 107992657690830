import { useTranslation } from 'react-i18next';
import { Lightning } from '../../../../components/Lightning';
import { formatCurrency } from '../../../../utils';
import KoobButton from '../../../../components/Koob/KoobButton';
import { useTripDesigner } from '../../../../hooks';

export default function ComposeHotelCard({ hotel, addHotel, hotelWithPrice, kind, isForTrip = false, isIncluded }) {
  const { t } = useTranslation('hotel');
  const { selectedType } = useTripDesigner();

  const hotelData = hotelWithPrice ? Object.assign(hotelWithPrice, hotel) : hotel;

  const styles = hotelData?.styles?.filter(s => !!s) ?? [];

  return (
    <div>
      <div className="relative text-left bg-white rounded-xl shadow-k overflow-hidden">
        <div className="relative">
          {hotelData?.allotmentAvailable && (
            <Lightning popover={t('instantAvaibility')} absolute={true} />
          )}

          {styles?.length > 0 && (
            <div
              className="absolute bottom-0 backdrop-blur-sm bg-purple-600 bg-opacity-50 w-full p-2"
            >
              <div className="text-center text-white text-xs">
                {styles?.map((style, index) => (
                  <span key={hotelData?.id + index}>{typeof style === 'string' ? style : style?.displayName}{index !== hotelData.styles.length - 1 && ', '}</span>
                ))}
              </div>
            </div>
          )}

          <img
            className="w-full h-56 object-cover bg-gray-100"
            src={hotelData?.imageUrl ?? '/img/hotelData-image-placeholder.png'}
            onError={ev => ev.target.src = '/img/hotel-image-placeholder.png'}
            alt=""
          />
        </div>

        <div className="px-4 py-4">
          <div className="flex justify-between">
            <div>
              {isForTrip ? (
                <a
                  href={`/hotels/${hotelData?.id}?source=trip`}
                  target="_blank"
                  className={"underline hover:text-blue-700"}
                  rel="noreferrer"
                >
                  <div className="font-medium">
                    {hotelData?.displayName}
                  </div>
                </a>
              ) : (
                <div className="font-medium">
                  {hotelData?.displayName}
                </div>
              )}

              {hotelData?.address && (
                <div className="mt-1 text-gray-500 text-sm flex space-x-1">
                  <div className="w-56 truncate">
                    {hotelData?.address}
                  </div>
                </div>
              )}

              {(!isIncluded && hotelData?.price) ? (
                <div className="mt-1 text-gray-500 text-sm">
                  <div className="w-56 truncate flex space-x-1">
                    <span>{t('from')}</span>
                    <span className="text-orange-600 font-medium">
                      {formatCurrency({
                        amount: hotelData?.price,
                        currency: hotelData?.currency
                      })}
                    </span>
                    <span>{t('perNight')}</span>
                  </div>
                </div>
              ) : ''}

              {isIncluded && (
                <div className="mt-1 text-green-600 font-medium text-sm">
                  {t('tripDesigner:compose.priceIncluded')}
                </div>
              )}
            </div>

            <div>
              <div className="flex space-x-1">
                <svg className="text-yellow-500 w-6 h-6" fill="currentColor" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                </svg>
                <div>
                  {hotelData?.stars ?? 0}
                </div>
              </div>

              {hotelData?.promotionsCount !== undefined && (
                <div className="mt-1 flex space-x-1">
                  <svg className={`${hotelData?.promotionsCount ? 'text-green-500' : 'text-gray-200'} h-6 w-6`} fill="currentColor" viewBox="0 0 448 512">
                    <path d="M0 80V229.5c0 17 6.7 33.3 18.7 45.3l176 176c25 25 65.5 25 90.5 0L418.7 317.3c25-25 25-65.5 0-90.5l-176-176c-12-12-28.3-18.7-45.3-18.7H48C21.5 32 0 53.5 0 80zm112 96c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/>
                  </svg>
                  <div>{hotelData?.promotionsCount ?? 0}</div>
                </div>
              )}
            </div>
          </div>

          <div className="my-1 text-xs text-gray-500/75 flex items-center justify-end space-x-1">
            <div>
              {t('providedBy')}
            </div>

            <div className="font-medium">
              {hotelData?.organization?.displayName || hotelData?.organization }
            </div>
          </div>

        {kind !== 'itinerary' &&
          <KoobButton
            onClick={() => addHotel(hotelData)}
            block
            disabled={selectedType !== 'hotel'}
          >
            {t(selectedType === 'hotel' ? 'misc:continue' : 'tripDesigner:compose.selectDay')}
          </KoobButton>}
        </div>
      </div>
    </div>
  );
}
