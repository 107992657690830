export const ProductTypeEnum = {
  EXPERIENCE: 'experience',
  HOTEL: 'hotel',
};

export const ProgramAccommodationTypeEnum = {
  NIGHT_TRANSFER: 'nightTransfer',
  NO_ACCOMMODATION: 'noAccomodation', // The typo is intentional to match the API
  FREE_ACCOMMODATION: 'freeAccomodation', // The typo is intentional to match the API
  HOTEL: 'hotel',
};

export const ExtraScopeEnum = {
  GLOBAL: 'global',
  DAY: 'day'
};

export const ExtraRequirementEnum = {
  REQUIRED: 'required',
  SUGGESTED: 'suggested',
};

export const ApiPollingStateEnum = {
  IN_PROGRESS: 'inProgress',
  FINISHED: 'finished'
};
