import React, { useState } from 'react';
import KoobTitle from 'components/Koob/KoobTitle';
import KoobParagraph from 'components/Koob/KoobParagraph';
import KoobButton from 'components/Koob/KoobButton';
import { useTranslation } from 'react-i18next';
import KoobModal from 'components/Koob/KoobModal';
import { Stack } from '@koob/margaret';
import { Text } from '../../../../ui';
import Stepper from '../../../../components/Stepper';

export default function DurationModal({ open, setOpen, onSave, initialValue, max = 50 }) {
  const { t } = useTranslation('tripDesigner');

  const [duration, setDuration] = useState(initialValue);

  return (
    <KoobModal open={open} setOpen={() => setOpen(false)} widthClass="sm:max-w-xl overflow-visible">
      <div className="text-center max-w-lg mx-auto">
        <KoobTitle size="text-xl">
          {t(`expDuration.title`)}
        </KoobTitle>
        <KoobParagraph size="text-sm">
          {t(`expDuration.description`)}
        </KoobParagraph>
      </div>

      <Stack size="full" alignX="space-between" className="mt-8">
        <Stack gutterSize={2}>
          <Stack direction="column" className="w-40">
            <Text type="h4" fontWeight="600" color="text" className="ml-2">
              {t('expDuration.title')}
            </Text>
          </Stack>
          <Stepper
            onChange={value => setDuration(value)}
            value={duration}
            max={max}
            min={1}
          />
        </Stack>
      </Stack>

      <div className="mt-8 flex justify-end space-x-2">
        <KoobButton onClick={() => {
          setOpen();
          onSave(duration);
        }} color="primary">
          {t('misc:save')}
        </KoobButton>
      </div>
    </KoobModal>
  )
}
